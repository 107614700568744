import React from 'react'
import styled from 'styled-components'
import { Login, Config } from './types'
import { connecterId } from '../../connectors/index'
import { connectorLocalStorageKey } from '../../../packages/uikit/src/widgets/WalletModal'

const WalletStyled = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
  row-gap: 6px;
  width: 100%;
  /* height: 114px; */
  /* padding: 10px 2rem; */
  /* border-radius: 10px; */
  cursor: pointer;
  /* background: rgba(255, 255, 255, 0.1); */

  &:hover {
    transform: scale(1.05);
  }

  .modal-title {
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-shadow: 1px 7px 4px rgb(111 27 47 / 35%), 0px -1px 0px #b64034;
    text-align: center;
    transition: 0.3s ease;
    margin-left: 2rem;
  }
  svg {
    transition: 0.3s ease;
  }

  /* &:hover {
    .modal-title {
      font-size: 15px;
    }
    svg {
      width: 42px;
    }
  } */

  ${({ theme }) => theme.mediaQueries.sm} {
    .modal-title {
      font-size: 16px;
      line-height: 100%;
    }

    /* &:hover {
      .modal-title {
        font-size: 18px;
      }
      svg {
        width: 42px;
      }
    } */
  }
`

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon: Icon } = walletConfig
  return (
    <WalletStyled
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
      onClick={() => {
        login(walletConfig.connectorId)
        window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
        onDismiss()
      }}
      style={{
        background: 'linear-gradient(97.44deg, #FFFFFF 4.49%, #FED8FF 71.33%, #DEFFF9 96.36%)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
      className="rounded-full px-6 py-1"
    >
      <Icon width="40px" />
      <div className="text-[17px] font-medium ml-4 flex-1 text-center">{title}</div>
    </WalletStyled>
  )
}

export default WalletCard
